$its-purple: #9F1F65;
$its-teal: #007C98;
$its-grey: #929292;
$its-lightgrey: #C4C4C4;
$its-white: #FFFFFF;
$its-darkgreen: #004650;
$its-darkblue: #1659A9;
$its-bright-blue: #53C2D2;
$primary70: #1768c9;
$primary05: #ebf5fd;
