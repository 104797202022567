// Enforce max/min size to prevent the flyout panel from resizing due to elements that modify the inner size eg accordion expander.
// The flyout contents panel does not accept style or classname so override it via selector. Ensures the chat support panel is the same size.
[data-test-id="get-support-flyout"] {
  [data-test-component="StencilFlyoutContent"] {
    min-width: 600px;
    max-width: 600px;
  }
}
// Due to the kind of hacky way the chat widget overlays the support flout, prevent there from being a jarring experience change by not dimming the background.
[data-test-component="StencilFlyoutBackdrop"] {
  opacity: 0 !important; // !important overrides inline style from component
}
