@use 'variables';

#app {
  #navbar {
    display: flex;
    flex: 1;
  }

  #languageSelector-toggle-button {
    border-radius: 5px;
  }
}

#languageSelectorLink {
  margin-right: 1rem; 
  border-color: transparent; 
  border-radius: 4px; 
  padding: 0px 12px;

  &:hover,
  &:focus {
    border-color: transparent;
    background-color: #E8EAEE;
    color: #0077D9;
  }
}
